import $ from "jquery";

let isHoovesisInitialized = false;
let windowHeight = parseInt($(window).outerHeight());

let HoovesPrint = function (selector, points, tension, segments, initialWidth = false) {

  tension = tension ? tension : 1;
  segments = segments ? segments : 16;

  // Impression des footprint
  var hooves = calculPath(getCurvePoints(points, tension, segments));
  for (let i in hooves) {
    // calcul en pourcentage (uniquement sur le left) des patounes
    var leftposition = hooves[i][0];
    if (initialWidth) {
      leftposition = leftposition * 100 / initialWidth + '%';
    } else {
      leftposition = leftposition + 'px';
    }
    $('<span class="hoof"></span>').appendTo(selector).css({
      left: leftposition,
      top: hooves[i][1],
      transform: 'rotate(' + hooves[i][2] + 'deg)'
    });
  }


  function getCurvePoints(pts, tension, numOfSegments) {

    // use input value if provided, or use a default value
    tension = (typeof tension != 'undefined') ? tension : 0.5;
    numOfSegments = numOfSegments ? numOfSegments : 32;

    var _pts = [], res = [],	// clone array
      x, y,			// our x,y coords
      t1x, t2x, t1y, t2y,	// tension vectors
      c1, c2, c3, c4,		// cardinal points
      st, t, i;		// steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    _pts.unshift(pts[1]);	//copy 1. point and insert at beginning
    _pts.unshift(pts[0]);
    _pts.push(pts[pts.length - 2]);	//copy last point and append
    _pts.push(pts[pts.length - 1]);

    // ok, lets start..
    var counter = 0
    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (let i = 2; i < (_pts.length - 4); i += 2) {
      for (t = 0; t < numOfSegments; t++) {
        // calc tension vectors
        t1x = (_pts[i + 2] - _pts[i - 2]) * tension;
        t2x = (_pts[i + 4] - _pts[i]) * tension;

        t1y = (_pts[i + 3] - _pts[i - 1]) * tension;
        t2y = (_pts[i + 5] - _pts[i + 1]) * tension;

        // calc step
        st = t / numOfSegments;

        // calc cardinals
        c1 = 2 * Math.pow(st, 3) - 3 * Math.pow(st, 2) + 1;
        c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2);
        c3 = Math.pow(st, 3) - 2 * Math.pow(st, 2) + st;
        c4 = Math.pow(st, 3) - Math.pow(st, 2);

        // calc x and y cords with common control vectors
        x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x;
        y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y;

        //store points in array
        res.push(x);
        res.push(y);

      }
    }

    return res;
  }

  function calculPath(pts) {
    var angleBetweenLines = 0;
    var angleWithNormal = 0;
    var beta1 = 0;
    var beta2 = 0;
    var distance = 15;
    var x1, y1, x2, y2;
    var hooves = [];

    for (let i = 2; i < pts.length - 1; i += 2) {

      // Détermination de l'emplacement des pattes autour du Path

      if (pts[i + 3] !== null) {
        // Calcul de l'angle entre deux segments
        angleBetweenLines = getAngleBetweenLines(pts[i - 2], pts[i - 1], pts[i], pts[i + 1], pts[i], pts[i + 1], pts[i + 2], pts[i + 3]);

        // Calcul de l'angle entre le premier segment et la normale
        angleWithNormal = getAngleBetweenLines(pts[i - 2], pts[i - 1], pts[i], pts[i + 1], pts[i], pts[i + 1], pts[i], pts[i + 1] + 100);

        // Calcul de l'angle entre la normale et l'axe de pate droite et gauche
        beta1 = angleBetweenLines / 2 - angleWithNormal;
        beta2 = beta1 + 180;

        // Calcul de l'emplacement des pates
        x1 = Math.cos((90 - beta1) * (Math.PI / 180)) * distance;
        y1 = Math.cos(beta1 * (Math.PI / 180)) * distance;
        x2 = Math.cos((90 - beta2) * (Math.PI / 180)) * distance;
        y2 = Math.cos(beta2 * (Math.PI / 180)) * distance;

        // Ajouts des sabots (1 sur deux)
        if (i % 4 === 0) {
          hooves.push([pts[i] + x1, pts[i + 1] + y1, angleWithNormal]);
        } else {
          hooves.push([pts[i] + x2, pts[i + 1] + y2, angleWithNormal]);
        }

      }
    }
    return hooves;
  }


  // Calcule d'angle
  function getAngleBetweenLines(x00, y00, x01, y01, x10, y10, x11, y11) {
    var dx0 = x01 - x00;
    var dy0 = y01 - y00;
    var dx1 = x11 - x10;
    var dy1 = y11 - y10;
    var angle = Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1);
    return 180 - (angle * 180 / 3.1415926)
  }
};

//remove hooves
function destroyHooves () {
  $('.hoof').remove();
  window.removeEventListener("scroll", scrollHooves );
  isHoovesisInitialized = false;
}

function scrollHooves () {

  // hoove print on scroll

  $('.hoof').each(function () {
    if ($(window).scrollTop() > $(this).position().top - (windowHeight * 0.75)) {
      $(this).addClass('hoof--active');
      if($('.hoof--active').length > 5) {
        var activeItem = $('.hoof--active')[0];
        $(activeItem).removeClass('hoof--active');
      }
    } else {
      $(this).removeClass('hoof--active');
    }
  });
}

function hoovesPrint() {

  // hooves already print ?
  if (!isHoovesisInitialized) {
    let selector = '[data-trigger="hooves-container"]';
    let myPoints = $(selector).data('points').split(',');
    let tension = $(selector).data('tension');
    let paws = $(selector).data('hooves-per-section');
    var initialWidth = false;
    if ($(selector).data('initial-width')) {
      initialWidth = $(selector).data('initial-width');
    }
    let activeScroll = $(selector).data('active-scroll');

    new HoovesPrint('#ground', myPoints, tension, paws, initialWidth);
    // active Scroll
    if (activeScroll) {
      window.addEventListener("scroll", scrollHooves );
    }
    isHoovesisInitialized = true;
  }
}


function hoovesInit() {
  if (breakpoints.isDesktop()) {
    hoovesPrint();
  } else {
    destroyHooves();
  }
}

$(document).ready(function(){
  hoovesInit();
  $(window).resize(function(){
    hoovesInit();
  });
});




